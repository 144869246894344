<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <div class="row justify-content-center">
        <div>
          <!-- <h4 style="color:#069" align="center">테크나인 IoT 플랫폼 </h4> -->
          <h1 align="center" id = "loginPageLogo">Smart IoT Platform</h1>
          <h5 align="center">계정에 로그인합니다</h5>
        </div>
      </div>
      <p />

      <CRow class="justify-content-center" >
        <!-- <CCol md="8"> -->
        <CCol md="4">
          <CCardGroup>
            <!-- <CCard class="p-4"> -->
            <CCard id = "loginFormCard">
              <CCardBody>
                <CForm @submit="onSubmit">
                  <h1 id = "loginFormCardHeader">로그인</h1>
                  <p class="text-muted">아이디/비밀번호 입력</p>
                  <CInput
                    v-model="email"
                    placeholder="Username"
                    autocomplete="username email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    v-model="password"
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <div class="form-group">
                    <div class="alert alert-danger" v-model = "message" role="alert" v-if="message">{{message}}</div> 
                  </div>
                  <hr id = "logigPageDivider">
                  <CRow>
                    <CCol col="12" class="text-left">
                        <CButton type="submit" class="btn btn-block btn-info"><span id="loginSubmitBtn">로그인</span></CButton>

<!--                         <CButton type="submit" class="btn btn-block btn-info"><span><FontAwesomeIcon :icon="['fa', 'arrow-right']"/> 로그인</span></CButton>
 -->                      <!-- <CButton color="primary" class="px-4" type="submit"><span><FontAwesomeIcon :icon="['fa', 'dot-circle']"/> 로그인</span></CButton> -->
                    </CCol>
                    <!-- <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton>
                    </CCol> -->
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import loopback from '@/services/loopback';
import EventBus from '@/services/EventBus'

export default {
  name: 'Login',
  props: {
    sessionError: {
      type: Error,
      default: null,
    },
  },
  data() {
    return {
      email: null,
      password: null,
      error: null,
      loading: false,
      message: ''
    };
  },
  watch: {
    sessionError: {
      handler(err) {
        this.error = err;
      },
      immediate: true,
    },
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.loading = true;
      this.error = null;

      // 로그인 시도
      this.$store
        .dispatch('auth/signIn', {
          email: this.email,
          password: this.password,
        })
        .then((token) => {
          // 로그인 성공
          this.$router.push({ name: 'Dashboard_' });
          this.loading = false;
          EventBus.$emit('subscribe');
        })
        .catch((err) => {
          this.message = err.message;
        });
    },
},
}
</script>
<style scoped>
*{ 
  font-family: 'Noto Sans KR', serif;
}
  #loginPageLogo{ 
    /* font-family: 'ubuntu'; */
/*     font-family: 'Orelega One';  */
/*      font-family: 'Poppins'; 
 */     font-family: 'Montserrat', sans-serif;
   }
  #loginHeader{ 
    font-family: 'Noto Sans KR'; 
  }
  #logigPageDivider{ 
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #loginFormCard{ 
    padding: 20px 30px 20px 30px
  }
  #loginFormCardHeader{ 
    margin-bottom: 15px;
  }
  #loginSubmitBtn{
    font-weight: bold;
  }


</style>
